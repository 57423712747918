import { Tire } from './../tire/tire';
export class Movement {
    summer?: string;
    winter?: string;
    summerTray?: string;
    winterTray?: string;
    id?: string;
    logs?: MovementLog[];
    summerTire?: Tire;
    winterTire?: Tire;
}

export class  MovementLog {
    type: string;
    created_at: Date;
    km: number;
}
