import { InventoryElement } from './../inventory/inventory';

export class Tire {
    width?: number;
    height?: number;
    diameter?: number;
    structure?: string;
    load?: number;
    category?: string;
    brand?: string;
    id?: string;
    tray?: string;
    barcode?: string;
    thumbs?: string;

    inventory?: InventoryElement;

    // $indexMisura: string = this.width + '/' + this.height + ' ' + this.structure + ' ' + this.diameter 
    //    + ' ' + this.load + ' ' + this.category;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
    getIndexMisure(index: string) {
        return (this.width + '/' + this.height + ' ' + this.structure + ' ' + this.diameter
            + ' ' + this.load + ' ' + this.category) === index;
    }

    getIndexGenericMisure(index: string) {
        return (this.width + '/' + this.height + ' ' + this.structure + ' ' + this.diameter
            + ' ' + this.load + ' ' + this.category).toLocaleLowerCase().split(' ').join('').replace('/', ' ')
            .includes(index.toLocaleLowerCase().split(' ').join('').replace('/', ' '));
    }
}


/* enum TireSpeedCategory {
    Q= 160,
    R= 170,
    S= 180,
    T= 190,
    H= 210,
    VR= 210,
    V= 240,
    ZR= 240,
    W= 270,
    Y= 300
} */
